import './styles/app.scss';
import './styles/cookieconsent.scss';

import 'jquery'
// loads the Bootstrap plugins
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/carousel';

// loads the code syntax highlighting library
import './js/highlight.js';

// Creates links to the Symfony documentation
import './js/doclinks.js';

// start the Stimulus application
import 'bootstrap';

const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');

import './js/flatpicker';

import '@popperjs/core';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import lax from 'lax.js';

import flatpickr from "flatpickr";

import Cookies from 'js.cookie';

// ..
AOS.init();

$("form#contactForm").on("submit", function(event){
    event.preventDefault();
    $.ajax({
        type: "POST",
        url: "",
        data: {"contact": $("#form_contact").val(), "message": $("#form_message").val()},
        dataType: "json",
        success: function(msg){
            console.log(msg);
            $('#contactUs').modal('hide');
            $('#emailModalSuccess').modal('show');
            $('#kontakt button').attr('data-bs-target', '#emailModalSuccess');
        },
        error: function(data) {
            console.log(data);        
            $('#contactUs').modal('hide');
            $('#emailModalSuccess').modal('show');
            $('#kontakt button').attr('data-bs-target', '#emailModalSuccess');
        }
    });
});

$('#ageCheck').modal({
    keyboard: false,
    backdrop: 'static'
});

$('#ageCheckFalse').modal({
    keyboard: false,
    backdrop: 'static'
});

$(document).ready(function () {

    //Cookies.remove('ageCheck');

    if((Cookies.get('ageCheck') == 'false')){
        $('#ageCheckFalse').modal('show');
    };

    if((Cookies.get('ageCheck') == undefined)) {
        $('#ageCheck').modal('show');
        $('#ageCheck .ageButton button').on('click', function(){
            // Extract info from data-bs-* attributes
            const check = $(this).data('bs-check')
            // If necessary, you could initiate an Ajax request here
            // and then do the updating in a callback.
            if(check == 1) {
                Cookies.set('ageCheck', 'true', { expires: 1 });
            } else {
                Cookies.set('ageCheck', 'false', { expires: 1 });
                $('#ageCheckFalse').modal('show');
            }
        });
    }
});

window.onload = function () {
    window.lax = { presets: lax.presets }
    lax.init()

    lax.addDriver('scrollY', function() {
        return window.scrollY
    })

    // Add animation bindings to elements
    lax.addElements('.floating-hand', {
        scrollY: {
            translateY: [
                [0, 150, 700],
                {

                    1199:[0, 0, 0],
                    1920:[0, 100, 700],
                    4000:[0, 100, 500],
                },
            ],
        }
    })
    // Add animation bindings to elements
    lax.addElements('.hand-special-animation', {
        scrollY: {
            translateY: [
                [0, 150, 350],
                {

                    1199:[0, -50, -150],
                    1920:[0, -50, -150],
                    4000:[0, -50, -150],
                },
            ],
        }
    })
}

var timelineSwiper = new Swiper ('.timeline .swiper-container', {
    direction: 'horizontal',
    loop: true,
    speed: 1000,
    effect: 'fade',
    pagination: '.swiper-pagination',
    paginationBulletRender: function (swiper, index, className) {
      var year = document.querySelectorAll('.swiper-slide')[index].getAttribute('data-year');
      return '<span class="' + className + '">' + year + '</span>';
    },
    paginationClickable: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    breakpoints: {
      768: {
        direction: 'horizontal',
      }
    }
});

$('#foodSwapper').click(function() {
    if($('#foodSwapper').is(":checked")){
        $('.product-detail h1').fadeOut(function() {
            $(this).html('Radler <br/> Mango <br/> Marakuja').fadeIn();
        });

        $('.floating-hand-no-animatiom').fadeOut(function() {
            $('.floating-hand-no-animatiom-second').delay(250).fadeIn();
        });
        
        $('.product-detail-info .material-1').fadeOut(function() {
            $('.product-detail-info .material-2').css("display", "flex").delay(250).fadeIn();
        });
        
        $('.product-detail-info .table-1').fadeOut(function() {
            $('.product-detail-info .table-2').delay(250).fadeIn();
        });

        $('.radler-swapper').attr('src','/build/images/produkt_3.png');
    }
    else {
        $('.product-detail h1').fadeOut(function() {
            $(this).html('Radler <br/> Citrón').fadeIn();
        });
        $('.floating-hand-no-animatiom-second').fadeOut(function() {
            $('.floating-hand-no-animatiom').delay(250).fadeIn();
        });
        
        $('.product-detail-info .material-2').fadeOut(function() {
            $('.product-detail-info .material-1').css("display", "flex").delay(250).fadeIn();
        });
        
        $('.product-detail-info .table-2').fadeOut(function() {
            $('.product-detail-info .table-1').delay(250).fadeIn();
        });

        $('.radler-swapper').attr('src','/build/images/produkt_4.png');
    }
});

$(document).ready(function () {
    const hamburger_menu = document.querySelector(".hamburger-menu");
    const navbar = document.querySelector(".device");
    let burger = document.getElementById('burger'),
        nav    = document.getElementById('main-nav');

    burger.addEventListener('click', function(e){
        this.classList.toggle('is-open');
        nav.classList.toggle('is-open');
    });

    hamburger_menu.addEventListener("click", () => {
        navbar.classList.toggle("active");
    });
});

/*
$(document).on('scroll', function(event) {
    var distance = $(this).scrollTop();

    if(distance > 0) {
        $('header').css('background', '#B08D4C');
    } else {
        $('header').css('background', 'transparent');
    }
});
*/





